export default function AppBar(theme) {
  return {
    MuiToolbar: {
      defaultProps: {
      },

      styleOverrides: {
        root: {
        },
      }
    }
  };
}
