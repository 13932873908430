export default function DialogTitle(theme) {
  return {
    MuiDialogTitle: {
      defaultProps: {
      },

      styleOverrides: {
        root: {
          fontSize: theme.typography.h4.fontSize,
        },
      }
    }
  };
}
