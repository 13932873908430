export default function Select(theme) {
  return {
    MuiSelect: {
      defaultProps: {
      },

      styleOverrides: {
        select: {
          borderRadius: '8px',
          borderColor: theme.palette.divider.dark,
        },
      }
    }
  };
}
