import React, { useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Container, Stack, Typography, Button, Box, Link, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Page from '@components/Page';
import InputFormControl from '@components/InputFormControl';
import { JOIN_WAITLIST } from '@apis/WaitlistApi';
import { useMutation } from '@apollo/client';
import { useHotkeys } from 'react-hotkeys-hook';

// import Logo from '@components/Logo';
// import Logo from './Logo';

const RootStyle = styled(Page)(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  flexDirection: 'column',
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(2),
  // backgroundImage: 'linear-gradient(to right, #ffb851 0%, #ffd586 100%)'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledInputFormControl = styled(InputFormControl)(({ theme, error }) => ({
  '& .MuiInputBase-input': {
    borderRadius: '9999px',
    fontSize: 18,
    padding: '12px 16px',
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      padding: '10px 12px',
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: 18,
  height: 56,
  paddingLeft: 48,
  paddingRight: 48,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
    height: 48,
    paddingLeft: 32,
    paddingRight: 32,
  },
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: 18,
  height: 56,
  paddingLeft: 48,
  paddingRight: 48,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
    height: 48,
    paddingLeft: 32,
    paddingRight: 32,
  },
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  position: 'relative',
  zIndex: 1,
}));

const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.grey[600],
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  }
}));

const AppIcon = styled('div')(({ top, left, right, size, rotate, idx, color }) => {
  // size width blur
  // 10   104   0.1
  // 9    96    0.2
  // 8    88    0.3
  // 7    80    0.4
  // 6    72    0.5
  // 5    64    1.5
  // 4    56    1.7
  // 3    48    1.9
  // 2    40    2.1
  // 1    32    2.3
  const width = 32 + 8 * (size - 1);
  const blur = size > 5 ? 0.5 - 0.1 * (size - 6) : 2.3 - 0.2 * (size - 1);

  return {
    position: 'absolute',
    top: top && `${top}px`,
    left: left && `${left}px`,
    right: right && `${right}px`,
    width: `${width}px`,
    height: `${width}px`,
    transform: rotate && `rotate(${rotate}deg)`,
    backgroundImage: `url(//assets.kiipu.com/img/apps.png?v=230730)`,
    backgroundSize: 'cover',
    backgroundPosition: `0 -${width * (idx - 1)}px`,
    filter: `blur(${blur}px) drop-shadow(rgba(145, 158, 171, 0.24) 0px 0px 8px)`,
  }
});

const JOIN_STATUS = {
  INIT: 0,
  FORM: 1,
  SUCCESS: 2,
}

const APP_ICONS = [
  { name: 'google', top: 32, left: 64, size: 7, rotate: 16, color: '#dfe1e5', idx: 1, solgan: 'Organize the World’s Information', url: 'https://google.com', hotkeyIdx: 1 },
  { name: 'bilibili', top: 216, left: 128, size: 6, rotate: 340, color: '#fb7299', idx: 8, solgan: '哔哩哔哩 (゜-゜)つロ 干杯', url: 'https://bilibili.com', hotkeyIdx: 3 },
  { name: 'dribbble', top: 16, right: 240, size: 7, rotate: 64, color: '#ea4c89', idx: 4, solgan: 'The Heart of the Designer Community', url: 'https://dribbble.com', hotkeyIdx: 6 },
  { name: 'youtube', top: 480, right: -32, size: 10, rotate: 24, color: '#dfe1e5', idx: 2, solgan: 'Broadcast Yourself', url: 'https://youtube.com', hotkeyIdx: 0 },
  { name: 'juejin', top: 320, right: 192, size: 6, rotate: 20, color: '#1e80ff', idx: 10, solgan: '一个帮助开发者成长的社区', url: 'https://juejin.cn', hotkeyIdx: 9 },
  { name: 'zhihu', top: 400, left: -32, size: 10, rotate: 24, color: '#056de8', idx: 6, solgan: '有问题，就会有答案', url: 'https://zhihu.com', hotkeyIdx: 5 },
  { name: 'x', top: 80, left: 256, size: 4, rotate: -16, color: '#000', idx: 5, solgan: 'See What\’s Happening', url: 'https://x.com', hotkeyIdx: 2 },
  { name: 'github', top: 200, right: 88, size: 5, rotate: 32, color: '#1e2327', idx: 3, solgan: 'Let\’s build from here', url: 'https://github.com', hotkeyIdx: 7 },
  { name: 'weixin', top: 320, left: 320, size: 2, rotate: 16, color: '#07c160', idx: 7, solgan: '微信，是一个生活方式', url: 'https://weixin.qq.com', hotkeyIdx: 4 },
  { name: 'smzdm', top: 224, right: 288, size: 2, rotate: 8, color: '#e62828', idx: 9, solgan: '科学消费，认真生活', url: 'https://smzdm.com', hotkeyIdx: 8 },
];

export default () => {
  const theme = useTheme();
  const inputRef = useRef();

  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [joinStatus, setJoinStatus] = useState(JOIN_STATUS.INIT);
  const [joinWaitlist, { data, loading, error }] = useMutation(JOIN_WAITLIST);
  const [solganEgg, setSolganEgg] = useState(false);
  const [showAppTips, setShowAppTips] = useState(Array(APP_ICONS.length).fill(false));

  useHotkeys([Array.from({ length: APP_ICONS.length }).map((_, idx) => idx)], (_, handler) => {
    const { url } = APP_ICONS.find(({ hotkeyIdx }) => hotkeyIdx === parseInt(handler.keys[0], 10))
    window.open(url);
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().trim().email('电子邮件地址格式不正确').required('电子邮件地址不能数为空'),
    }),
    validateOnBlur: validateAfterSubmit,
    validateOnChange: validateAfterSubmit,
    onSubmit: (values, { setSubmitting }) => {
      joinWaitlist({
        variables: values,
        onCompleted: () => {
          setJoinStatus(JOIN_STATUS.SUCCESS);
          setSubmitting(false);
        },
        onError: () => {
          setSubmitting(false);
        }
      });
    }
  })

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <RootStyle title="奇普书签 - 你喜爱的，汇集一处">
      <div
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          overflow: 'hidden',
          display: matchUpMd ? 'block' : 'none',
          // pointerEvents: 'none',
        }}
      >
        {
          APP_ICONS.map((item) => (
            <Tooltip
              title={item.solgan}
              open={showAppTips[item.idx - 1]}
              enterDelay={500}
              leaveDelay={200}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <AppIcon
                {...item}
                onDoubleClick={() => {
                  setShowAppTips(() => {
                    const newShowAppTips = [...showAppTips];
                    newShowAppTips[item.idx - 1] = !newShowAppTips[item.idx - 1];
                    return newShowAppTips;
                  });
                }}
              />
            </Tooltip>
          ))
        }
      </div>
      <Container maxWidh="sm">
        <ContentStyle>
          <Stack sx={{ mb: matchUpSm ? 6 : 4, alignItems: 'center' }}>
            {/* <Logo viewBox="0 0 146 80" sx={{ width: 146, height: 80,  mb: 2 }} /> */}
            {/* <Logo viewBox="0 0 48 48" sx={{ fontSize: '72px',  mb: 4 }} /> */}
            <img
              src="//assets.kiipu.com/img/logo.png?v=230723"
              style={{ width: matchUpSm ? 160 : 120, marginBottom: matchUpSm ? 64 : 32, zIndex: 1 }}
              onDoubleClick={() => {
                setSolganEgg(() => !solganEgg);
                setShowAppTips(Array(APP_ICONS.length).fill(false));
              }}
            />
            <Typography variant="h1" sx={{ fontSize: matchUpSm ? '48px' : '32px', fontWeight: 'bold', zIndex: 1 }}>
              {
                solganEgg ? 'Your Favorites, All Here!' : '你喜爱的，汇集一处'
              }
            </Typography>
          </Stack>
        </ContentStyle>
        <Box sx={{ mb: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'center', textAlign: 'center', width: matchUpSm ? '480px' : '100%' }}>
            {
              joinStatus === JOIN_STATUS.INIT ? (
                <StyledButton
                  variant="contained"
                  color="primary"
                  size={matchUpSm ? 'large' : 'medium'}
                  onClick={() => {
                    setJoinStatus(JOIN_STATUS.FORM);
                    setTimeout(() => {
                      inputRef.current.focus()
                    }, 0)
                  }}
                >
                  加入候选名单
                </StyledButton>
              ) : null
            }
            {
              joinStatus === JOIN_STATUS.FORM ? (
                <>
                  {
                    matchUpSm ? (
                      <IconButton
                        color="inherit"
                        aria-label="back"
                        style={{ marginRight: 16, marginTop: 9.5 }}
                        onClick={() => {
                          setJoinStatus(JOIN_STATUS.INIT);
                          resetForm();
                        }}
                      >
                        <ArrowBackIcon sx={{ color: theme.palette.text.secondary }} />
                      </IconButton>
                    ) : null
                  }
                  <FormikProvider value={formik}>
                    <Form
                      autoComplete="off"
                      noValidate
                      style={{
                        width: matchUpSm ? '480px' : '88%',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: matchUpSm ? 'row' : 'column'
                      }}
                      onSubmit={handleSubmit}
                    >
                      <Box sx={{ flex: 1, mr: matchUpSm ? 2 : 0, mb: matchUpSm ? 0 : 2 }}>
                        <StyledInputFormControl
                          inputRef={inputRef}
                          type="email"
                          placeholder="电子邮件地址"
                          autoComplete="email"
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          {...getFieldProps('email')}
                        />
                      </Box>
                      <StyledLoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        size={matchUpSm ? 'large' : 'medium'}
                        loading={isSubmitting}
                        loadingPosition="start"
                        onClick={() => {
                          setValidateAfterSubmit(true);
                        }}
                      >
                        立即加入
                      </StyledLoadingButton>
                    </Form>
                  </FormikProvider>
                </>
              ) : null
            }
            {
              joinStatus === JOIN_STATUS.SUCCESS ? (
                <Typography variant={matchUpSm ? 'h3' : 'h4'} sx={{ fontWeight: 'normal', display: 'flex', alignItems: 'center' }}>
                  <DoneIcon sx={{ color: theme.palette.success.main, mr: 1 }} />你已加入候选名单
                </Typography>
              ) : null
            }
          </Stack>
          <Link href="/login" style={{ marginTop: 16, textDecoration: 'none', fontSize: matchUpSm ? '16px' : '14px', zIndex: 1 }}>
            已有账号
          </Link>
          <div
            style={
              matchUpSm ? {
                marginTop: 64,
                zIndex: -1,
              } : {
                marginTop: 40,
                width: '100vw',
                overflow: 'auto',
                padding: '8px 24px',
              }
            }
          >
            <img
              src="//assets.kiipu.com/img/hero/web_231031.jpg"
              style={{
                width: matchUpSm ? '100%' : '640px',
                border: '1px solid rgba(145, 158, 171, 0.24)',
                borderRadius: matchUpSm ? '32px' : '16px',
                filter: 'drop-shadow(rgba(145, 158, 171, 0.24) 0px 0px 8px)',
                zIndex: -1,
              }}
            />
          </div>
        </Box>
      </Container>
      <Footer>
        <Stack direction="row" spacing={3} sx={{ justifyContent: 'center' }}>
          <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>©2024 Kiipu</Typography>
          <StyledLink href="mailto: kiipu.app@gmail.com">
            <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>联系我们</Typography>
          </StyledLink>
          <StyledLink href="https://beian.miit.gov.cn/" target="_blank">
            <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>京ICP备14010856号-2</Typography>
          </StyledLink>
        </Stack>
      </Footer>
      {/* <footer className="flex flex-col items-center justify-center mt-16 z-10">
        <div className="flex items-center text-sm text-gray-500 mt-8">
          <div>©2024 Kiipu</div>
          <a target="_blank" href="/explore" className="hover:underline ml-8">发现</a>
          <i className="inline-block border-r border-r-gray-100 h-3 pl-4 mr-4"></i>
          <button id="mega-menu-dropdown-button" data-dropdown-toggle="mega-menu-dropdown" className="hover:underline">
            联系我们
          </button>
          <i className="inline-block border-r border-r-gray-100 h-3 pl-4 mr-4"></i>
          <a target="_blank" href="https://beian.miit.gov.cn/" className="text-gray-500 hover:underline">京ICP备14010856号-2</a>
        </div>
        <div id="mega-menu-dropdown" className="absolute z-100 grid hidden w-auto grid-cols-2 text-sm bg-white border border-gray-100 rounded-lg shadow-md">
          <div className="p-4 pb-0 text-gray-900 md:pb-4 dark:text-white">
            <ul className="space-y-4" aria-labelledby="mega-menu-dropdown-button">
              <li>
                <a target="_blank" href="https://twitter.com/KiipuApp" className="text-gray-500 hover:underline">
                  X
                </a>
              </li>
              <li>
                <a target="_blank" href="https://discord.gg/u8PKkUYVce" className="text-gray-500 hover:underline">
                  Discord
                </a>
              </li>
              <li>
                <a target="_blank" href="https://weibo.com/u/7858148641" className="text-gray-500 hover:underline">
                  微博
                </a>
              </li>
            </ul>
          </div>
          <div class="p-4">
            <ul class="space-y-4">
              <li>
                <a target="_blank" href="https://v.douyin.com/idqEerUX/" className="text-gray-500 hover:underline">
                  抖音
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.xiaohongshu.com/user/profile/64c72e3a000000000e026e37" className="text-gray-500 hover:underline">
                  小红书
                </a>
              </li>
              <li>
                <a target="_blank" href="https://okjk.co/jRzy9x" className="text-gray-500 hover:underline">
                  即刻
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer> */}
    </RootStyle>
  )
}
