import { gql } from "@apollo/client";

// 加入候选名单
export const JOIN_WAITLIST = gql`
mutation WaitlistJoin($email: String!) {
  waitlistJoin(email: $email) {
    waitlist {
      id
    }
  }
}
`;

// 获取候选名单列表
export const GET_WAITLIST = gql`
  query waitlist {
    waitlist {
      id
      email
      createdAt
    }
  }
`;
