import { alpha, useTheme, styled } from '@mui/material/styles';

export default function Link(theme) {
  return {
    MuiLink: {
      defaultProps: {
      },

      styleOverrides: {
        root: {
          cursor: 'pointer',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline'
          }
        }
      }
    }
  };
}
