import {
  useMemo
} from 'react';
import {
  createTheme,
  useTheme
} from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import colors from '../assets/scss/_themes-vars.module.scss';
import componentsOverride from './overrides';
import paletteDefault from './palette/default';
import paletteDark from './palette/dark';
import typography from './typography';

const setWebappStatusBarColor = (color) => {
  document.querySelector('meta[name=theme-color]').setAttribute('content', color);
}

export default function ThemeConfig({ theme, autoDarkTheme }) {
  // const themess = useTheme();
  // const color = colors;

  // const themeOption = {
  //     colors: color,
  //     heading: color.grey900,
  //     paper: color.paper,
  //     backgroundDefault: color.paper,
  //     background: color.primaryLight,
  //     darkTextPrimary: color.grey700,
  //     darkTextSecondary: color.grey500,
  //     textDark: color.grey900,
  //     menuHoverBack: color.grey50,
  //     menuSelected: color.secondaryDark,
  //     menuSelectedBack: color.secondaryLight,
  //     divider: color.grey200,
  //     customization
  // };

  // const themeOptions = {
  //     direction: 'ltr',
  //     palette: themePalette(themeOption),
  //     mixins: {
  //         toolbar: {
  //             minHeight: '48px',
  //             padding: '16px',
  //             '@media (min-width: 600px)': {
  //                 minHeight: '48px'
  //             }
  //         }
  //     },
  //     typography: themeTypography(themeOption)
  // };

  const prefersDarkTheme = useMediaQuery('(prefers-color-scheme: dark)');

  const showDarkPalette = theme === 'DARK' || (autoDarkTheme && prefersDarkTheme);

  if (showDarkPalette) {
    setWebappStatusBarColor(paletteDark.background.paper);
  } else {
    setWebappStatusBarColor(paletteDefault.background.paper);
  }

  const themeOptions = useMemo(() => ({
    palette: showDarkPalette ? {
      mode: 'dark',
      ...paletteDark,
    } : {
      mode: 'light',
      ...paletteDefault
    },
    typography,
    mixins: {
      toolbar: {
        minHeight: 48,
        '@media (min-width:1200px)': {
          minHeight: 56,
        },
      }
    },
    shadows: ["none"],
  }), [theme, prefersDarkTheme, autoDarkTheme]);

  const customizationTheme = createTheme(themeOptions);

  customizationTheme.components = componentsOverride(customizationTheme);

  return customizationTheme;
};
