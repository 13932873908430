import { alpha, useTheme, styled } from '@mui/material/styles';

export default function MenuItem(theme) {
  return {
    MuiMenuItem: {
      defaultProps: {
        primaryTypographyProps: {
          variant: 'body2',
        }
      },

      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected:hover, &.Mui-selected.Mui-focusVisible': {
            backgroundColor: theme.palette.action.hover,
          }
        }
      }
    }
  };
}
