export default function DialogActions(theme) {
  return {
    MuiDialogActions: {
      defaultProps: {
      },

      styleOverrides: {
        root: {
          padding: theme.spacing(2),
        },
      }
    }
  };
}
