export default function Button(theme) {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: 14,
          borderRadius: '9999px',
          // fix: 修复按钮背景颜色被Tailwindcss覆盖的问题
          '&.MuiButton-containedPrimary:not(.Mui-disabled)': {
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark
            }
          },
        },
      },
    }
  };
}
