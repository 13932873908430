export default function SnackbarContent(theme) {
  return {
    MuiSnackbarContent: {
      defaultProps: {
      },
      styleOverrides: {
        root: {
          borderRadius: '9999px',
        },
        message: {
          // fontSize: theme.typography.body1.fontSize,
        },
      }
    }
  };
}
