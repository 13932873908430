import { alpha, useTheme, styled } from '@mui/material/styles';

export default function ListItemText(theme) {
  return {
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          variant: 'body2',
        },
      },

      styleOverrides: {
        root: {
        }
      }
    }
  };
}
