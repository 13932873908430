import { alpha, useTheme, styled } from '@mui/material/styles';

export default function SvgIcon(theme) {
  return {
    MuiSvgIcon: {
      defaultProps: {
      },

      styleOverrides: {
        // fontSizeSmall: { fontSize: '1rem' },
        // fontSizeMedium: { fontSize: '1.25rem' },
        // fontSizeLarge: { fontSize: '1.5rem' },
        // root: {
        // }
      }
    }
  };
}
