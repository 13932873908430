export default function Snackbar(theme) {
  return {
    MuiSnackbar: {
      defaultProps: {
      },
      styleOverrides: {
        root: {
          [theme.breakpoints.down('sm')]: {
            bottom: [`calc(56px + constant(safe-area-inset-bottom) + 16px)`, `calc(56px + env(safe-area-inset-bottom) + 16px)`]
          },
        },
      }
    }
  };
}
