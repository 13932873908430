import React from 'react';
import { Typography, InputBase, FormControl, FormHelperText } from '@mui/material';
import { styled, alpha, useTheme } from '@mui/material/styles';

const StyledOutlinedInput = styled(InputBase)(({ theme, error }) => ({
  '& .MuiInputBase-input': {
    borderRadius: theme.spacing(1),
    position: 'relative',
    border: '1px solid',
    fontSize: 14,
    padding: '10px 12px',
    margin: '2px 0',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    borderColor: error ? theme.palette.error.main : theme.palette.divider.dark,
    '&:focus': {
      boxShadow: `${alpha(error ? theme.palette.error.main : theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
    },
  },
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(0.25),
  fontSize: theme.typography.body2.fontSize,
})); 

export default (props) => {
  const {
    label,
    type,
    placeholder,
    autoComplete,
    error,
    helperText,
    disabled,
    ...otherProps
  } = props;

  const theme = useTheme();

  return (
    <FormControl fullWidth>
      { label && <StyledLabel>{label}</StyledLabel> }
      <StyledOutlinedInput
        fullWidth
        autoComplete={autoComplete}
        type={type}
        placeholder={placeholder}
        error={error}
        disabled={disabled}
        {...otherProps}
      />
      {helperText && (
        <FormHelperText error={error} sx={{ fontSize: 14, ml: 0 }}>
          { helperText }
        </FormHelperText>
      )}
    </FormControl>
  )
}