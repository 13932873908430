import { alpha } from '@mui/material/styles';

export default function Menu(theme) {
  return {
    MuiMenu: {
      defaultProps: {
        elevation: 0
      },

      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            borderRadius: 6,
            minWidth: 220,
            color: theme.palette.text.primary,
            boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            ...theme.palette.mode === 'dark' && {
              boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            },
            '& .MuiMenu-list': {
              padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
              fontSize: theme.typography.body2.fontSize,
              '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
              },
              '&:active': {
                // backgroundColor: alpha(
                //   theme.palette.primary.main,
                //   theme.palette.action.selectedOpacity,
                // ),
              },
            },
          },
        },
        rounded: {
        }
      }
    }
  };
}
