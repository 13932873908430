export default function CssBaseline(theme) {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
          overflowY: 'scroll',
          overscrollBehaviorY: 'none',
        },
        body: {
          height: '100%',
        },
      }
    }
  };
}
